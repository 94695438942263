import {
  Box,
  Container,
  Flex,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import Section from "@bottlebooks/bottlebooks-site-base/src/components/Section/Section";
import React from "react";
import { CaretDoubleDown } from "@phosphor-icons/react";

export default function Tickets() {
  function scrollDown() {
    window.scrollTo({
      top:
        window.innerWidth < 460
          ? window.innerWidth * 0.56
          : window.innerWidth * 0.56 + 100,
      behavior: "smooth",
    });
  }
  return (
    <Section
      sx={{
        paddingY: 0,
        margin: "auto",
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: "56.25%",
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: -50,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
        width="640"
        height="360"
        src={
          "https://www.youtube.com/embed/hRTi2k5DTwI?controls=0&amp;rel=0&amp;playsinline=1&amp;origin=https%3A%2F%2Fwww.wine-trophy.com&amp;widgetid=1&autoplay=1&mute=1&start=5"
        }
      />

      <Box
        sx={{
          width: "100%",
          height: "100%",
          top: -50,
          position: "absolute",
          backgroundColor: "black",
          opacity: 0.5,
          zIndex: -1,
        }}
      />
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: ["5%", "10%"],
        }}
      >
        <Container
          sx={{ maxWidth: "container.fluid", textAlign: "center", zIndex: 1 }}
        >
          <img
            src={`https://res.cloudinary.com/bottlebooks/image/upload/c_fit,w_800/bottlebooks/54fdd71acbf8c2aeb644f35a/2024_12_11_7_57_51-weinmesse-berlin-logo-8904.png`}
            style={{ width: "100%" }}
          />
          <Title
            sx={{ color: "white", marginTop: -30, fontSize: [16, 22, 26, 32] }}
          >
            die größte Publikumsweinmesse Deutschlands
          </Title>
          <Flex
            sx={{
              color: "white",
              alignItems: "center",
              flexDirection: "column",
              flexGrow: 0,
              flexShrink: 0,
              justifyContent: "flex-start",
            }}
          >
            <Box sx={{ marginTop: [1, 15, 30, 40] }} onClick={scrollDown}>
              <CaretDoubleDown size={42} weight="bold" />
            </Box>
          </Flex>
        </Container>
      </Flex>
    </Section>
  );
}
