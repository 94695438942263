/** @jsxImportSource theme-ui */
// @ts-ignore TS doesn't like direct image imports.
import { useCollectionLayout } from "../CollectionLayoutProvider.next";
import PortWineFestFooter from "./Footer.PortWineFest";
import StandardFooter from "./Footer.Standard";
import WeinmesseBerlinFooter from "./Footer.WeinmesseBerlin";

export default function Footer({
  children,
  homePath = "",
  ...rest
}: {
  children: React.ReactNode;
  homePath: string;
}) {
  const collectionLayout = useCollectionLayout();
  switch (collectionLayout.siteConfig.layout) {
    case "PORTWINEFEST":
      return (
        <PortWineFestFooter homePath={homePath} {...rest}>
          {children}
        </PortWineFestFooter>
      );
    case "WEINMESSEBERLIN":
      return (
        <WeinmesseBerlinFooter homePath={homePath} {...rest}>
          {children}
        </WeinmesseBerlinFooter>
      );
    default:
      return (
        <StandardFooter homePath={homePath} {...rest}>
          {children}
        </StandardFooter>
      );
  }
}
