import {
  Box,
  Container,
  Flex,
  Grid,
  P,
  Text,
  Title,
  View,
} from "@bottlebooks/gatsby-theme-base/src";

import React, { useEffect } from "react";

export default function Tickets() {
  return (
    <Flex direction="column" align="center">
      <Section sx={{ paddingY: 4 }}>
        <Grid columns={[1, 2]}>
          <Box>
            <Title>Buchen Sie hier Ihr Ticket für die Weinmesse Berlin</Title>
          </Box>
          <Box>
            <P>
              Bitte wählen Sie Ihren Besuchstag vorab aus. Die Anzahl der
              Tickets können Sie im nächsten Schritt eintragen.
            </P>
            <P>
              Für einen entspannten Besuch kommen Sie direkt Freitag oder
              Samstag um 13 Uhr oder am Sonntag, da Freitag bzw. Samstag
              Nachmittag mit höheren Wartezeiten zu rechnen ist.
            </P>
            <P>Zugang für Besucherinnen und Besucher ab 18 Jahren.</P>
          </Box>
        </Grid>
      </Section>
      <Section sx={{ paddingTop: 2 }}>
        <UniverseTicketWidget />
      </Section>
    </Flex>
  );
}

function Section({ children, ...rest }: { children: React.ReactNode }) {
  return (
    <View
      sx={{
        paddingY: 4,
      }}
      {...rest}
    >
      <Container fluid sx={{ maxWidth: "container.fluid" }}>
        {children}
      </Container>
    </View>
  );
}
const UniverseTicketWidget = () => {
  useEffect(() => {
    const existingScript = document.getElementsByClassName("tmjs-iframe");
    if (existingScript.length > 0) {
      existingScript[0].parentElement?.removeChild(existingScript[0]);
    }
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://www.universe.com/embed2.js";
    script.async = true;

    // Add custom data attributes
    script.setAttribute("data-widget-type", "universe-ticket");
    script.setAttribute("data-target-type", "Listing");
    script.setAttribute("data-target-id", "weinmesse-berlin-tickets-PWC2GX");
    script.setAttribute(
      "data-state",
      "buttonColor=#3A66E5&buttonText=Get Tickets"
    );
    const thiscomponent = document.getElementById("universe-ticket-widget");
    // Append the script to the document body
    thiscomponent?.appendChild(script);

    // Cleanup function to remove the script
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return <div id="universe-ticket-widget" style={{ width: 600 }}></div>;
};
