import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/600.css";
import merge from "lodash/merge";
import { lighten } from "polished";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import { useCollectionLayout } from "../../CollectionLayoutProvider.next";
import baseTheme from "../../../theme";

export default function WeinmesseBerlinLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <style type="text/css">
          {`
        // Mantine defaults to antialiased font smoothing, which looks terrible
        :root {
          --mantine-webkit-font-smoothing: subpixel-antialiased;
        }
        html, body {
          -webkit-font-smoothing: subpixel-antialiased;
        }
    `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  const collectionLayout = useCollectionLayout();
  const primaryColor = collectionLayout.primaryColor || "#540638";
  const accentColor = collectionLayout.accentColor || "#aa9059";
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: primaryColor,
      secondary: lighten(0.1)(primaryColor || "#000000"),
      accent: accentColor,
      // primary: "#540638",
    },
    fonts: merge({}, baseTheme.fonts, {
      heading: "Montserrat, sans-serif",
      body: '"Open sans", serif',
    }),
    fontSizes: {
      ...baseTheme.fontSizes,
      largestTitle: 42, // 68 in base
      largeTitle: 32, // 42 in base
      title: 24, // 32 in base
      smallTitle: 20, // 24 in base
      smallestTitle: 18, // 20 in base
    },
    fontWeights: {
      ...baseTheme.fontWeights,
      heading: "600",
    },
    title: merge({}, baseTheme.title, {
      _base: {
        WebkitFontSmoothing: "antialiased",
      }, // Add antialiasing to titles.
    }),
    text: merge({}, baseTheme.text, {
      semiTitle: {
        ...baseTheme.text.semiTitle,
        fontSize: "body",
      },
    }),
    styles: {
      ...baseTheme.styles,
      root: {
        ...baseTheme.styles.root,
        WebkitFontSmoothing: "subpixel-antialiased", // Garamond and DIN look bad with antialised in small sizes.
      },
    },
  };
}
