/** @jsxImportSource theme-ui */
import {
  Box,
  FacebookIcon,
  Flex,
  Grid,
  InstagramIcon,
  Label,
  Text,
  TwitterIcon,
  YoutubeIcon,
} from "@bottlebooks/gatsby-design-system";
import { BottlebooksLogo, Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
// import LinkedInIconSVG from "../../../../assets/LinkedInIcon.svg";
// @ts-ignore TS doesn't like direct image imports.
import backToTop from "@bottlebooks/bottlebooks-site-base/src/assets/back-to-top.svg";
import { useCollection } from "../SiteSearch/useEventData";
import useLink from "../../useLink";

export default function WeinmesseBerlinFooter({
  children,
  homePath = "",
  ...rest
}: {
  children: React.ReactNode;
  homePath: string;
}) {
  return (
    <Wrapper {...rest}>
      <Top sx={{ marginTop: 4 }}>{children}</Top>
      {/* <Bottom /> */}
      <Logos />
      <FooterLinks sx={{ marginY: 3 }} />
    </Wrapper>
  );
}

export function Wrapper({ children, ...rest }: { children: React.ReactNode }) {
  return (
    <Box
      as="footer"
      sx={{
        variant: "styles.Footer",
        paddingY: [2, 4],
        paddingX: [2, 5],
        backgroundColor: "background",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function Top({ children, ...rest }: { children: React.ReactNode }) {
  function handleScrollToTop(e) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    e.preventDefault();
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: [2, 4],
        borderBottom: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      {children}
      <Link
        href="#"
        onClick={handleScrollToTop}
        sx={{ marginLeft: "auto", color: "black" }}
      >
        <Label>
          <Trans>Back to the top</Trans>{" "}
          <img src={backToTop} alt="" sx={{ verticalAlign: "middle" }} />
        </Label>
      </Link>
    </Box>
  );
}

function Logos() {
  const widths = [60, 70, 110, 140];
  return (
    <Flex
      justify="space-between"
      align="center"
      sx={{ borderBottom: 1, borderColor: "border" }}
    >
      {sponsors.map((sponsor, index) => (
        <Link
          key={`${sponsor.name}_${index}`}
          href={sponsor.url}
          target="_blank"
        >
          <img src={sponsor.imageUrl} sx={{ width: widths }} height="100%" />
        </Link>
      ))}
    </Flex>
  );
}

function FooterLinks({ ...rest }) {
  const link = useLink();
  return (
    <Grid columns={1} {...rest}>
      <Flex
        direction={["column", "row", "row", "row"]}
        justify="center"
        align="center"
      >
        <Link to={link.base + "/faqs"} sx={{ marginX: 2 }}>
          <Text variant="small">FAQ</Text>
        </Link>
        <Link to={link.base + "/kontakt"} sx={{ marginX: 2 }}>
          <Text variant="small">Kontakt</Text>
        </Link>
        <Link to={link.base + "/impressum"} sx={{ marginX: 2 }}>
          <Text variant="small">Impressum</Text>
        </Link>
        <Link to={link.base + "/datenschutz"} sx={{ marginX: 2 }}>
          <Text variant="small">Datenschutzerklärung</Text>
        </Link>
        <Link to={link.base + "/agb"} sx={{ marginX: 2 }}>
          <Text variant="small">Allg. Geschäftsbedingungen</Text>
        </Link>
        <Link to={link.base + "/teilnahmebedingungen"} sx={{ marginX: 2 }}>
          <Text variant="small">Teilnahmebedingunen</Text>
        </Link>
      </Flex>
      <Flex gap={3} justify="center">
        {socialMedia.map((socialMediaItem, index) => (
          <Link
            key={`${socialMediaItem.type}_${index}`}
            href={socialMediaItem.url}
            sx={{ width: 30 }}
          >
            <SocialMediaItem item={socialMediaItem} />
          </Link>
        ))}
      </Flex>
    </Grid>
  );
}

function SocialMediaItem({ item }: { item: { type: string; url: string } }) {
  switch (item.type) {
    case "TWITTER":
      return <TwitterIcon size="small" />;
    case "INSTAGRAM":
      return <InstagramIcon size="small" />;
    case "FACEBOOK":
      return <FacebookIcon size="small" />;
    case "YOUTUBE":
      return <YoutubeIcon size="small" />;
    // case "LINKEDIN":
    //   return <img src={LinkedInIconSVG} />;
    default:
      return null;
  }
}

export function Bottom({ ...rest }) {
  return (
    <Flex align="baseline" gap={2} sx={{ paddingTop: [2, 4] }} {...rest}>
      <Text variant="smallest" sx={{ color: "lightText" }}>
        <Flex align="center">
          <Trans>Powered by</Trans>{" "}
          <Link
            href="https://www.bottlebooks.me"
            external
            sx={{ textTransform: "uppercase" }}
          >
            <BottlebooksLogo title="Bottlebooks®" />
          </Link>
        </Flex>
        <Text variant="smallest">
          <Trans>Data management for the wine and drinks industry</Trans>
        </Text>
      </Text>

      <Text variant="smallest" sx={{ marginLeft: "auto", color: "lightText" }}>
        2021
      </Text>
    </Flex>
  );
}

/** @typedef {Array<Sponsor>}  */
const sponsors = [
  {
    name: "Deutsche Wein Marketing",
    url: "https://www.wine-trophy.com/de/",
    imageUrl:
      "https://cdn.bottlebooks.me/54fdd71acbf8c2aeb644f35a/2024_12_11_8_33_44-deutscheWeinMarketingLogo-4380.png",
  },
  {
    name: "Wein-Plus",
    url: "https://www.wein.plus/de",
    imageUrl:
      "https://cdn.bottlebooks.me/54fdd71acbf8c2aeb644f35a/2024_12_11_8_33_38-weinPlusLogo-3015.png",
  },
  {
    name: "Don't Drink and Drive",
    url: "https://ddad.de",
    imageUrl:
      "https://cdn.bottlebooks.me/54fdd71acbf8c2aeb644f35a/2024_12_11_8_33_25-dontDrinkAndDriveLogo-6052.jpg",
  },
  {
    name: "Wine in Moderation",
    url: "https://www.wineinmoderation.eu",
    imageUrl:
      "https://cdn.bottlebooks.me/54fdd71acbf8c2aeb644f35a/2024_12_11_8_33_31-wineInModerationLogo-8642.png",
  },
  // { name: 'Selters', url: 'https://www.selters.de', imageUrl: seltersLogo },
  // { name: 'Vinum', url: 'https://www.vinum.eu/de', imageUrl: vinumLogo },
  // {
  //   name: 'Zwiesel Glass',
  //   url: 'https://www.zwiesel-glas.com',
  //   imageUrl: zwieselLogo,
  // },
];

/**
 * @typedef Sponsor
 * @property {string} name
 * @property {string} url
 * @property {string} imageUrl
 */

const socialMedia = [
  { type: "FACEBOOK", url: "https://www.facebook.com/WEINmesse" },
  // { type: 'TWITTER', url: 'https://twitter.com/WineTrophy' },
  // { type: 'INSTAGRAM', url: 'https://www.instagram.com/berlinerwinetrophy/' },
  // {
  //   type: 'LINKEDIN',
  //   url: 'https://www.linkedin.com/showcase/berliner-wine-trophy',
  // },
];

// LinkedInIconSVG
// <?xml version="1.0" encoding="utf-8"?>
// <!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
// <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
// 	 viewBox="0 0 54 54" >
// <style type="text/css">
// 	.st0{fill:none;}
// </style>
//     <rect width="39" height="39" ry="39" fill="#98002e"/>
// <g transform="scale(0.60) translate(6.5 6.5)">

// 	<path fill="#fff" d="M48.1,12.9v26.8c0,2.2-0.8,4.1-2.4,5.7c-1.6,1.6-3.5,2.4-5.7,2.4H13.3c-2.2,0-4.1-0.8-5.7-2.4c-1.6-1.6-2.4-3.5-2.4-5.7
// 		V12.9c0-2.2,0.8-4.1,2.4-5.7c1.6-1.6,3.5-2.4,5.7-2.4H40c2.2,0,4.1,0.8,5.7,2.4C47.3,8.8,48.1,10.7,48.1,12.9z M18.7,15.4
// 		c0-1-0.4-1.8-1-2.4C17,12.3,16.2,12,15.1,12c-1.1,0-2,0.3-2.6,0.9c-0.7,0.6-1,1.4-1,2.4c0,0.9,0.3,1.7,1,2.4c0.7,0.6,1.5,1,2.6,1h0
// 		c1.1,0,2-0.3,2.7-1C18.4,17.1,18.7,16.3,18.7,15.4z M11.8,40.7h6.4V21.3h-6.4V40.7z M35,40.7h6.4V29.6c0-2.9-0.7-5-2-6.5
// 		c-1.4-1.5-3.2-2.2-5.4-2.2c-2.5,0-4.5,1.1-5.8,3.3h0.1v-2.8h-6.4c0.1,1.2,0.1,7.7,0,19.4h6.4V29.9c0-0.7,0.1-1.2,0.2-1.6
// 		c0.3-0.7,0.7-1.2,1.3-1.7c0.6-0.5,1.2-0.7,2.1-0.7c2.2,0,3.2,1.5,3.2,4.4V40.7z"/>
// </g>
// </svg>
